@charset "utf-8";

.root {

.fl {float: left;}
.fr {float: right;}
.cl {clear:both !important;}

.indent {
	text-indent: -1em !important;
	margin-left: 1em !important;
}

.psr {position: relative !important;}
.psa {position: absolute !important;}

.lhl {line-height: 2;}
.lhm {line-height: 1.6;}

.tac {text-align: center !important;}
.tal {text-align: left !important;}

.fwb {
	font-weight: bold !important;
}
.fwn {
	font-weight: normal !important;
}
.dib {display: inline-block !important;}
.db {display: block !important;}
.dn {display: none !important;}
.dif {display: flex !important;}
.j-between {justify-content: space-between;}
.j-center {justify-content: center;}
.a-center {align-items: center;}

.mt0 {margin-top: 0 !important;}
.mt5 {margin-top: 5px !important;}
.mt10 {margin-top: 10px !important;}
.mt15 {margin-top: 15px !important;}
.mt20 {margin-top: 20px !important;}
.mt25 {margin-top: 25px !important;}
.mt30 {margin-top: 30px !important;}
.mt35 {margin-top: 35px !important;}
.mt40 {margin-top: 40px !important;}
.mt45 {margin-top: 45px !important;}
.mt50 {margin-top: 50px !important;}
.mt1em {margin-top: 1em !important;}
.mt2em {margin-top: 2em !important;}

.mb0 {margin-bottom: 0 !important;}
.mb5 {margin-bottom: 5px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb25 {margin-bottom: 25px !important;}
.mb30 {margin-bottom: 30px !important;}
.mb35 {margin-bottom: 35px !important;}
.mb40 {margin-bottom: 40px !important;}
.mb45 {margin-bottom: 45px !important;}
.mb50 {margin-bottom: 50px !important;}
.mb1em {margin-bottom: 1em !important;}
.mb2em {margin-bottom: 2em !important;}

.ml0 {margin-left: 0 !important;}
.ml5 {margin-left: 5px !important;}
.ml10 {margin-left: 10px !important;}
.ml15 {margin-left: 15px !important;}
.ml20 {margin-left: 20px !important;}
.ml25 {margin-left: 25px !important;}
.ml30 {margin-left: 30px !important;}
.ml35 {margin-left: 35px !important;}
.ml40 {margin-left: 40px !important;}
.ml45 {margin-left: 45px !important;}
.ml50 {margin-left: 50px !important;}
.ml1em {margin-left: 1em !important;}
.ml2em {margin-left: 2em !important;}
.mlauto {margin-left: auto !important;}

.mr0 {margin-right: 0 !important;}
.mr5 {margin-right: 5px !important;}
.mr10 {margin-right: 10px !important;}
.mr15 {margin-right: 15px !important;}
.mr20 {margin-right: 20px !important;}
.mr25 {margin-right: 25px !important;}
.mr30 {margin-right: 30px !important;}
.mr35 {margin-right: 35px !important;}
.mr40 {margin-right: 40px !important;}
.mr45 {margin-right: 45px !important;}
.mr50 {margin-right: 50px !important;}
.mr1em {margin-right: 1em !important;}
.mr2em {margin-right: 2em !important;}
.mrauto {margin-right: auto !important;}

.mlrauto {margin-left: auto; margin-right: auto;}

.pt0 {padding-top: 0 !important;}
.pt5 {padding-top: 5px !important;}
.pt10 {padding-top: 10px !important;}
.pt15 {padding-top: 15px !important;}
.pt20 {padding-top: 20px !important;}
.pt25 {padding-top: 25px !important;}
.pt30 {padding-top: 30px !important;}
.pt35 {padding-top: 35px !important;}
.pt40 {padding-top: 40px !important;}
.pt45 {padding-top: 45px !important;}
.pt50 {padding-top: 50px !important;}
.pt1em {padding-top: 1em !important;}
.pt2em {padding-top: 2em !important;}

.pb0 {padding-bottom: 0 !important;}
.pb1em {padding-bottom: 1em !important;}
.pb2em {padding-bottom: 2em !important;}

.pl0 {padding-left: 0 !important;}
.pl1em {padding-left: 1em !important;}
.pl2em {padding-left: 2em !important;}

.pr0 {padding-right: 0 !important;}
.pr1em {padding-right: 1em !important;}
.pr2em {padding-right: 2em !important;}

.wdt100p {width: 100% !important;}

.fwLight { font-weight: 300; }
.fwRegular { font-weight: 400; }
.fwMedium { font-weight: 500; }
.fwBold { font-weight: 700; }
.fwBlack { font-weight: 900; }

.taLeft {
  text-align: left;
}

.wsPre {
  white-space: pre-wrap;
}

.fcWht {
	color: #fff;
}
.fcRed {
	color: #f00;
}

.is-fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

/* clearfix
----------------------------------------------------------- */
.clf:after,
.clf:before {
	content: "";
	display: table;
}
.clf:after {
	clear: both;
}

/* module end
------------------------------------------------------------------------------------------------------- */

.link {
  text-decoration: underline;
  color: #005ECC;
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }
}
} /* .root */
