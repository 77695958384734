@charset "utf-8";

.root-licensee {

/* l-header
--------------------------------- */

.l-header .wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 81px;
}

.header-logo {
	display: flex;
	align-items: center;
}

.header-logo .header_login-logo_img {
	display: block;
}

.header-logo .header_login-logo_img + .header_login-logo_img {
	margin-left: 20px;
}

.header-logo .logo-roys {
	height: 30px;
}

.header-logo .logo-aniplex {
	height: 30px;
}

.header-links {
	display: flex;
	align-items: center;
}

.header-links a {
	text-decoration: none;
}

.header-links .link-user a:hover {
	text-decoration: underline;
	text-underline-offset: .15em;
}

.header-links .btn-user {
	position: relative;
	margin-left: 27px;
	z-index: 1;
}

.header-links .btn-user .js-nav_trigger {
	position: relative;
	display: flex;
	align-items: center;
	width: 174px;
	height: 40px;
	padding-left: 20px;
	padding-right: 35px;
	background-color: #19415A;
	border-radius: 4px;
	line-height: 1;
	font-weight: bold;
	color: #fff;
	cursor: pointer;
	transition: all ease .3s;
	z-index: 1;
}

.header-links .btn-user .js-nav_trigger:hover {
	opacity: .8;
}

.header-links .btn-user .js-nav_trigger:before {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	width: 12px;
	height: 7px;
	background-image: url(../../assets/licensee_img/icn-arrow_wht.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	transition: all ease .3s;
}

.header-links .btn-user .js-nav_trigger.is-active:before {
	transform: translateY(-50%) rotate(180deg);
}

.header-links .btn-user .js-nav_body {
	position: absolute;
	top: 40px;
	width: 100%;
	pointer-events: none;
}

.header-links .btn-user .js-nav_trigger.is-active + .js-nav_body {
	pointer-events: auto;
}

.header-links .btn-user .js-nav_body li a {
	display: block;
	width: 100%;
	padding: 10px 0 9px;
	background-color: #0e2431;
	text-align: center;
	color: #fff;
}

.header-links .btn-user .js-nav_body li:first-child a {
	border-radius: 4px 4px 0 0;
}

.header-links .btn-user .js-nav_body li:last-child a {
	border-radius: 0 0 4px 4px;
}

.header-links .btn-user .js-nav_body li + li {
	border-top: solid 1px #19415A;
}

.header-links .btn-logout {
	width: 130px;
	margin-left: 10px;
}

.header-nav_list {
	display: flex;
	align-items: center;
}

.header-nav_list li {
	width: calc(100% / 3);
}

.header-nav_list li + li {
	border-left: solid 1px #E3D3D4;
}

.header-nav_list li.active + li {
	border-left: none;
}

.header-nav_list a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 100%;
	background-color: #F6EDEE;
	text-decoration: none;
	font-weight: bold;
	color: #000;
	transition: color ease .3s
}

.header-nav_list a:hover {
	color: #C94962;
}

.header-nav_list .active a {
	background: rgb(208,138,146);
	background: linear-gradient(45deg, rgba(208,138,146,1) 0%, rgba(167,37,77,1) 100%);
	color: #fff;
	cursor: default;
}

.header-nav_list .active a:hover {
	opacity: 1;
	color: #fff;
}

/* l-header_login
--------------------------------- */
.l-header_login .wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 148px;
}

.header_login-logo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.header_login-logo .header_login-logo_img {
	display: block;
}

.header_login-logo .header_login-logo_img + .header_login-logo_img {
	margin-top: 11px;
}

.header_login-logo .logo-roys {
	height: 34px;
}

.header_login-logo .logo-aniplex {
	height: 30px;
}



/* l-main
--------------------------------- */

/* l-main_login
--------------------------------- */
.l-main_login {
	background-color: #F8F8F8;
}

.main_login-head {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	background: rgb(208,138,146);
	background: linear-gradient(45deg, rgba(208,138,146,1) 0%, rgba(167,37,77,1) 100%);
	font-weight: bold;
	font-size: 2.0rem;
	color: #fff;
}

.main_login-body {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 80px 0;
}

.main_login-read + .main_login-content {
	margin-top: 30px;
}

.main_login-content {
	width: 800px;
	margin-right: auto;
	margin-left: auto;
	padding: 50px 70px 50px 50px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0,0,0,.16);
}

.main_login-form + .main_login-form {
	margin-top: 20px;
}

.main_login-form dt {
	flex: 0 0 170px;
}

.main_login-form + .btn {
	width: 160px;
}

.link-question {
	margin-top: 30px;
}

.main_login-form {
  .form-body {
    display: block;
  }
}


/* l-footer
--------------------------------- */
.l-footer {
	position: relative;
	margin-top: 5px;

}

.l-footer:before {
	content: "";
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	height: 5px;
	background: rgb(228,118,131);
	background: linear-gradient(90deg, rgba(228,118,131,1) 0%, rgba(134,123,184,1) 100%);
	pointer-events: none;
}

.l-footer .wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 59px;
}

/* l-footer_login
--------------------------------- */
.l-footer_login {
	position: relative;
	margin-top: 5px;
}

.l-footer_login:before {
	content: "";
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	height: 5px;
	background: rgb(228,118,131);
	background: linear-gradient(90deg, rgba(228,118,131,1) 0%, rgba(134,123,184,1) 100%);
	pointer-events: none;
}

.l-footer_login .wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 58px;
}

/* l-pagetop
--------------------------------- */
.l-pagetop {
	cursor: pointer;
	transition: opacity ease .3s;
}

.l-pagetop:hover {
	opacity: .8;
}

/* l-copyright
--------------------------------- */
.l-copyright small {
	font-size: .9rem;
}

/* layout end
------------------------------------------------------------------------------------------------------- */

} /* .root-licensee */
