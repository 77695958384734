@charset "utf-8";

@import url("common/default.scss");
@import url("common/module.scss");
@import url("common/content.scss");

body {
	width: 100%;
	min-width: 1440px;
}
