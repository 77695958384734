.root-licensee,
.root-aniplex {
/* ポップアップ用追加スタイル */
.l-popup {
  .popup-title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    text-align: center;
  }

  &.flexible {
    .popup-content {
      height: auto;
      width: fit-content;
      max-width: 50%;
      min-width: 300px;
      margin: 0;
      position: fixed;
      top: calc(50% + 50px);
      left: 50%;
      transform: translate(-50%, calc(-50% - 30px));
    }

    .popup-body {
      padding: 20px;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        width: 160px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  &.txt-center {
    .popup-body {
      .wrap {
        text-align: center;
        .msg-wrap {
          display: inline-block;
          .msg {
            text-align: left;
          }
        }
      }
    }
  }

  &.no-btn-close {
    .popup-content {
      transform: translate(-50%, -50%);
    }
    .btn-close {
      display: none;
    }
  }
}
/* ポップアップ用追加スタイル end */

/* loading */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 200;
  animation: loading-fadeIn 1s ease 1s 1 normal forwards;

  @keyframes loading-fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  .loader-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      font-size: 10px;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      animation: loader-anim 1.1s infinite linear;
    }
    @keyframes loader-anim {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
/* loading end */

/* datepicker */
&, .input-form {

  .react-datepicker {
    font-size: 12px;

    &-popper {
      padding-top: 5px !important;
    }

    &__triangle {
      display: none;
    }

    &-wrapper {
      width: 100%;
      height: 100%;
    }

    &__input-container {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 12px auto;
      background-color: #fff;

      input {
        line-height: 1;
        font-size: 1.4rem;
        border: solid 1px #DEDEDE;
        background: transparent;
        width: 100%;
        height: 100%;
      }
    }

    &__current-month,
    &-time__header,
    &-year-header {
      font-size: 12px;
    }

    &__day,
    &__day-name,
    &__time-name {
      width: 1.7em;
    }
  }
}
/* datepicker end */

/* searchable-list-box */
.searchable-list-box {
  input:focus {
    box-shadow: none;
  }
}
/* searchable-list-box end */

/* error-list */
.error_list {
  margin-top: 5px;

  &-item {
    // color: #c63861;
    color: #FF0000;
    font-weight: bold;
    line-height: 1.5;
  }
  &-item:before {
    content:url(../../assets/aniplex_img/icn-attention.svg);
    width: 1.5rem;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
    top: 2px;
  }
}
/* error-list end */

/* help-message */
.help {
  position: relative;
  cursor: help;
  padding-left: 5px;
  color: #4682b4;
}

.description {
  width: 400px;
  position: absolute;
  padding: 8px;
  border-radius: 10px;
  background-color: #333333;
  color: #fff;
	line-height: 1.2;
  top: 100%; /* Y軸の位置 */
  z-index: 1;
  transition: 0.5s all;
  visibility: hidden;
  opacity: 0;
}

.help:hover .description {
  visibility: visible;
  opacity: 1;
}
/* help-message end */
}
