@charset "utf-8";

.root-aniplex {

/* l-header
--------------------------------- */
.l-header {
	position: relative;
	z-index: 100;
}

.l-header .wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 81px;
}

.header-logo {
	display: flex;
	align-items: center;
}

.header-logo .header_login-logo_img {
	display: block;
}

.header_login-aniplex {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: bottom;
	height: 29px;
	line-height: 29px;
	margin-left: 20px;
	padding: 0 10px;
	border: solid 1px #4D4294;
	font-weight: bold;
	color: #4D4294;
}

.header-logo .logo-roys {
	height: 30px;
}

.header-links {
	display: flex;
	align-items: center;
}

.header-links a {
	text-decoration: none;
}

.header-links .link-user a:hover {
	text-decoration: underline;
	text-underline-offset: .15em;
}

.header-links .btn-master {
	position: relative;
	margin-left: 27px;
	z-index: 1;
}

.header-links .btn-master .js-nav_trigger {
	position: relative;
	display: flex;
	align-items: center;
	width: 142px;
	height: 40px;
	padding-left: 20px;
	padding-right: 35px;
	background-color: #C9B300;
	border-radius: 4px;
	line-height: 1;
	font-weight: bold;
	color: #fff;
	cursor: pointer;
	transition: all ease .3s;
	z-index: 1;
}

.header-links .btn-master .js-nav_trigger:hover {
	opacity: .8;
}

.header-links .btn-master .js-nav_trigger:before {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	width: 12px;
	height: 7px;
	background-image: url(../../assets/aniplex_img/icn-arrow_wht.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	transition: all ease .3s;
}

.header-links .btn-master .js-nav_trigger.is-active:before {
	transform: translateY(-50%) rotate(180deg);
}

.header-links .btn-master .js-nav_body {
	position: absolute;
	top: 40px;
	width: 100%;
	pointer-events: none;
}

.header-links .btn-master .js-nav_trigger.is-active + .js-nav_body {
	pointer-events: auto;
}

.header-links .btn-master .js-nav_body li a {
	display: block;
	width: 100%;
	padding: 10px 0 9px;
	background-color: #8D7D00;
	text-align: center;
	color: #fff;
}

.header-links .btn-master .js-nav_body li:first-child a {
	border-radius: 4px 4px 0 0;
}

.header-links .btn-master .js-nav_body li:last-child a {
	border-radius: 0 0 4px 4px;
}

.header-links .btn-master .js-nav_body li + li {
	border-top: solid 1px #C9B300;
}

.header-nav_list {
	display: flex;
	align-items: center;
}

.header-nav_list > li {
	position: relative;
	width: calc(100% / 4);
	border-left: solid 1px #DED9F0;
}

.header-nav_list > li.active + li {
	border-left: none;
}

.header-nav_list > li > a,
.header-nav_list > li > p {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 100%;
	background-color: #F4F2FF;
	text-decoration: none;
	font-weight: bold;
	color: #200F6F;
	transition: color ease .3s;
	cursor: pointer;
}

.header-nav_list > li > a:hover {
	color: #000;
}

.header-nav_list > li.active > a:hover {
	color: #fff;
}


.header-nav_list .toggle-sub-nav p {
	position: relative;
}

.header-nav_list .toggle-sub-nav p:before {
	content: "";
	position: absolute;
	top: 50%;
	right: 20px;
	width: 12px;
	height: 7px;
	background-image: url(../../assets/aniplex_img/icn-arrow_aniplex.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	transition: all ease .3s;
	transform: translateY(-50%) rotate(0);
}

.header-nav_list .toggle-sub-nav:hover p:before {
	transform: translateY(-50%) rotate(180deg);
}

.header-nav_list .active > a,
.header-nav_list .active > p {
	background: #7365B9;
	background: linear-gradient(90deg, #7365B9 0%, #1F0E6F 100%);
	color: #fff;
	cursor: default;
}

.header-nav_list .active p:before  {
	background-image: url(../../assets/aniplex_img/icn-arrow_wht.svg);
}

.aniplex .header-nav_list .active a:hover {
	opacity: 1;
	color: #fff;
}

.toggle-sub-nav:hover > .header-sub-nav_list {
	height: 121px;
	pointer-events: auto;
}

.header-sub-nav_list {
	position: absolute;
	top: 60px;
	left: 0;
	width: 100%;
	height: 0;
	pointer-events: none;
	overflow: hidden;
	transition: height ease .3s;
}

.header-sub-nav_list > li a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	background-color: rgba(79, 64, 153, .95);
	text-decoration: none;
	font-weight: bold;
	color: #fff;
	transition: all ease .3s;
}

.header-sub-nav_list > li a:hover {
	background-color: rgba(79, 64, 153, 1);
}

.header-sub-nav_list > li + li {
	border-top: solid 1px #7569B5;
}

/* l-footer
--------------------------------- */
.l-footer {
	position: relative;
	margin-top: 5px;
}

.l-footer:before {
	content: "";
	position: absolute;
	top: -5px;
	left: 0;
	width: 100%;
	height: 5px;
	background: rgb(228,118,131);
	background: linear-gradient(90deg, rgba(228,118,131,1) 0%, rgba(134,123,184,1) 100%);
	pointer-events: none;
}

.l-footer .wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 59px;
}

/* l-pagetop
--------------------------------- */
.l-pagetop {
	cursor: pointer;
	transition: opacity ease .3s;
}

.l-pagetop:hover {
	opacity: .8;
}

/* l-copyright
--------------------------------- */
.l-copyright small {
	font-size: .9rem;
}

/* layout end
------------------------------------------------------------------------------------------------------- */

} /* .root-aniplex */
